import { core } from "../../../../config/pluginInit";
import { _ } from "vue-underscore";
import leadDetail from "./lead-detail.vue";
import leadQuotation from "./lead-quotation.vue";
import leadSales from "./lead-sales.vue";
import leadClients from "./lead-clients.vue";
import leadhistory from "./lead-History.vue";
import mailCompose from "./mailCompose/lead-email.vue";
import leadCall from "./lead-call/lead-call.vue";
import leadMessage from "./lead-message/lead-message.vue";
import assignModal from "./lead-assign.vue";
import feedbackModal from "./lead-feedback.vue";
import leadRequest from "./lead-request.vue";
import leadMarketingInfo from "./lead-marketing-info.vue";
import phoneCallModal from "../list/phoneCall-modal.vue";
import askExpert from "./askExpert-modal.vue";
import expertComment from "./expertComment-modal.vue";
import leadFeedbackComment from "./leadFeedbackComments-modal.vue";
import store from "@/store/index.js";

export default {
  name: "AddUser",
  components: {
    "lead-details": leadDetail,
    "lead-quotation": leadQuotation,
    "lead-sales": leadSales,
    "lead-call": leadCall,
    "lead-message": leadMessage,
    "lead-clients": leadClients,
    "lead-History": leadhistory,
    "lead-email": mailCompose,
    "lead-email": mailCompose,
    "lead-assign": assignModal,
    "lead-feedback": feedbackModal,
    "lead-request": leadRequest,
    "lead-marketing-info": leadMarketingInfo,
    "phoneCall-modal": phoneCallModal,
    "askExpert-modal": askExpert,
    "expertComment-modal": expertComment,
    "leadFeedbackComments-modal": leadFeedbackComment,
  },
  data() {
    return {
      id: "",
      currentTab: "",
      selectedLead: null,
      selectedLeadList: [],
      takeLeadExpertlist: [],
      askExertlist: [],
      markQuatedlist: [],
      modalShow: true,
      selectedAction: "",
      userslist: "",
      leadData: {},
      is_expert_asked: 1,
      currentComponent: null,
      newComment: "",
      showaskExpert: false,
      showExpertComment: false,
      showFeedbackComment: false,
      companies: [],
      callQueuePresence:
        parseInt(localStorage.getItem("call_queue_presence")) || 0,
      userId: JSON.parse(atob(localStorage.getItem("user"))).id,
    };
  },
  computed: {
    hasmarketingTabPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-marketing-info-show";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    isSelfLead() {
      return this.leadData.assign_to == this.userId;
    },
    isExpertSelfLead() {
      return this.leadData.expert_assign_to == this.userId;
    },
    user() {
      return this.$store.state.userDetail;
    },
    fullName: function () {
      return this.user.firstname + " " + this.user.lastname;
    },
    isExpertAsked() {
      return this.leadData.is_expert_asked === 1;
    },

    leadId() {
      return this.$store.state.leadId;
    },
    isTakeExperttPermission() {
      const permission = this.hasTakeExperttPermission();
      return permission;
    },
    isMartQuotedPermission() {
      const permission = this.hasMartQuotedPermission();
      return permission;
    },

    isAskExpertPermission() {
      const permission = this.hasAskExpertPermission();
      return permission;
    },
    isFeedbackPermission() {
      const permission = this.hasFeedbackPermission();
      return permission;
    },
    isReOpenPermission() {
      const permission = this.hasReOpenPermission();
      return permission;
    },
    isRLeadDeletePermission() {
      const permission = this.hasRLeadDeletePermission();
      return permission;
    },
    isActionbuttonPermission() {
      return this.isassignPermission || this.isFeedbackPermission;
    },
    isassignPermission() {
      const permission = this.hasassignPermission();
      return permission;
    },
    isActionhidePermission() {
      const permission = this.hasActionhidePermission();
      return permission;
    },
    isQuotedPermission() {
      const permission = this.hasQuotedHidePermission();
      return permission;
    },
  },
  methods: {
    TransferredCompany(company) {
      let _vm = this;
      this.$dialog
        .confirm(
          `Are you sure you want to transfer this Company ${company.name}?`
        )
        .then(function () {
          store.state.isLoaderShow = true;
          const leadIds = Array.isArray(_vm.$store.state.leadId)
            ? _vm.$store.state.leadId
            : [_vm.$store.state.leadId];

          _vm.axios
            .post(`/transfer-leads/`, {
              leadIds: leadIds,
              company: company.slug,
            })
            .then(function () {
              store.state.isLoaderShow = false;
              _vm.getAll();
            })
            .catch(function (error) {
              store.state.isLoaderShow = false;
              console.error(error);
            });
        })
        .catch(function () {});
    },
    initializeCompanies() {
      const companiesString = process.env.VUE_APP_TRANSFERS_TO_COMPANIES;
      if (companiesString) {
        this.companies = companiesString.split(",").map((slug) => {
          const name = slug
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return {
            name: name,
            slug: slug,
          };
        });
      }
    },
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hasActionhidePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = ["leads-assign", "leads-feedback"];

      let hasPermission = desiredData.some((item) => slugsList.includes(item));
      return hasPermission;
    },
    hasassignPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-assign";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasFeedbackPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-feedback";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasReOpenPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-reopen";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasRLeadDeletePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-delete";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasTakeExperttPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-take-expert";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasMartQuotedPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-mark-quoted";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasAskExpertPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-ask-expert";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasQuotedHidePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-supervisor-quoted";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    // addfeedback() {
    //     let _vm = this;
    //     this.axios
    //         .post("/lead-feedback", {
    //             lead_id: this.selectedLead.id,
    //             comment: this.newComment, is_feedback: 1
    //         })
    //         .then(function (response) {
    //             _vm.modalShow = false;
    //             _vm.newComment = "";
    //         })
    //         .catch(function (error) {
    //         });
    // },
    changeTab(tab) {
      this.currentTab = tab;
      window.location.hash = tab;
    },
    modelClose() {
      this.currentComponent = "";
    },

    updateLeadDetail(lead) {
      this.selectedLead = lead;
      this.selectedLeadList = [lead];
    },

    openaskExpert() {
      this.showaskExpert = !this.showaskExpert;
    },
    openExpertComment() {
      this.showExpertComment = !this.showExpertComment;
    },
    openFeedbackComment() {
      this.showFeedbackComment = !this.showFeedbackComment;
    },
    takeLeadExpert() {
      let _vm = this;
      this.axios
        .post("/take-lead-expert", { lead_id: this.$store.state.leadId })
        .then(function (response) {
          _vm.takeLeadExpertlist = response.data.data;
          _vm.getDetail();
        })
        .catch(function (error) {});
    },
    markQuated() {
      let _vm = this;
      this.axios
        .post("/mark-quoted", { lead_id: this.$store.state.leadId })
        .then(function (response) {
          _vm.markQuatedlist = response.data.data;
          _vm.getDetail();
        })
        .catch(function (error) {});
    },
    takeQuoted() {
      this.axios
        .post("/supervisor-quoted", { lead_id: this.$store.state.leadId })
        .then(function (response) {})
        .catch(function (error) {});
    },
    leadReopen() {
      let lead_id = this.$store.state.leadId;
      let _msg = "Are you sure you want to Reopen this lead?";
      this.$dialog.confirm(_msg).then(() => {
        this.axios
          .get("/lead-reopen/" + lead_id)
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {});
      });
    },
    getDetail() {
      let _vm = this;
      if (this.$route.params.id > 0) {
        this.axios
          .get("/leads/" + this.$route.params.id)
          .then(function (response) {
            let data = JSON.parse(atob(response.data.data));
            _vm.leadData = data;
            let first_name = data.client.first_name;
            let last_name = data.client.last_name;
            let lead_number = data.id;
            let customTitle =
              first_name +
              " " +
              (last_name || "") +
              " (" +
              lead_number +
              ") " +
              "|" +
              " " +
              "CRM" +
              " " +
              "Lead";
            document.title = customTitle;
            _vm.$emit("updateLeadDetail", data);
            _vm.updateLeadDetail(data);
            _vm.$store.state.leadId = data.id;
            _vm.$route.meta = { title: customTitle, customTitle: true };
          })
          .catch(function (error) {});
      }
    },
    LeadDeleted(id) {
      let _vm = this;
      let _msg = `Are you sure you want to Delete this lead? ` + id;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          this.axios
            .delete("/lead-soft-delete/" + id)
            .then(function (response) {
              window.location.href = "/lead";
            })
            .catch(function () {});
        })
        .catch(function () {});
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.userslist = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.getDetail();
    this.initializeCompanies();
    if (window.location.hash && window.location.hash.length > 0) {
      let hash = window.location.hash.split("#")[1];
      this.changeTab(hash);
    } else {
      this.changeTab("lead-info");
    }
    this.getUserList();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
};
